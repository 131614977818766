export default class Initial {
    _initial = false

    _callback

    constructor(callback) {
        this._callback = callback
    }

    call() {
        return new Promise(resolve => {
            if (!this._initial) {
                this._callback(() => {
                    this._initial = true
                    resolve()
                })
            } else resolve()
        })
    }

    static of(callback) {
        return new Initial(callback)
    }
}
